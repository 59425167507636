import React, { useEffect } from 'react'
import MenuContainer from './menuContainer'
import './menu.css'

const BurgerMenu = ({ menuClosed, setMenuClosed }) => {

    useEffect(() => {

        window.addEventListener('click', handleOffMenuClick )

        return(() => {
            window.removeEventListener('click', handleOffMenuClick )
        })
    })

    const handleOffMenuClick = ( e ) => {
        if( !menuClosed ) {
            if( document.elementsFromPoint( e.clientX, e.clientY )
                .filter(( item ) => item.id === 'menu-modal').length === 0 ) {
                    menuSlide()
            }
        }
    }

    const menuSlide = () => {

        setMenuClosed( !menuClosed )

        const menu = document.getElementById('menu-modal').style
        const span1 = document.getElementById('menu-spans').firstChild.style
        const span2 = document.getElementById('menu-spans').childNodes[1].style
        const span3 = document.getElementById('menu-spans').childNodes[2].style 
        const menuItems = document.querySelectorAll('.menu-title-containers > a > h2')
        const menuLinks = document.querySelectorAll('.menu-title-containers > a, .menu-title-containers')


        if( menuClosed ){

            menu.zIndex = 99
            menu.transform = "translateX(-100%)"
            menu.boxShadow = '-15px 0 25px 5px rgba(0, 0, 0, 0.356)'
            menu.transitionDuration = "0.4s"
            menu.transitionTimingFunction = 'ease-in'
            for( let item of menuItems) {
                item.style.transform = "translateY(0%)"
                item.style.transitionDuration = "1s"
                item.style.transitionDelay = "0.1s"
                item.style.pointerEvent = 'all'
                item.style.zIndex = 99
            }
            for( let item of menuLinks) {
                item.style.pointerEvent = 'all'
                item.style.zIndex = 99
            }

            span1.transform = "rotate(405deg)"
            span1.transformOrigin = "0% 95%"
            span1.width = "2.75rem"
            span1.transitionDuration = "0.3s"
            span1.backgroundColor = "white"

            span2.opacity = "0"
            span2.transitionDuration = "0.5s"

            span3.transform = "rotate(135deg)"
            span3.transformOrigin = "45% 0%"
            span3.width = "2.75rem"
            span3.transitionDuration = "0.5s"
            span3.backgroundColor = "white"


        } else {
            menu.zIndex = 0
            menu.transform = ""
            menu.boxShadow = 'none'
            menu.transitionTimingFunction = 'ease-in'
            menu.transitionDuration = "0.5s"

            for( let item of menuItems) {
                item.style.transform = "translateY(400%)"
                item.style.transitionDelay = "0.3s"
                item.style.transitionDuration = "0.8s"
                item.style.zIndex = 0
            }
            for( let item of menuLinks) {
                item.style.zIndex = 0
                item.style.pointerEvent = 'none'
            }
            
            span1.transform = "rotate(0deg)"
            span1.width = "2rem"
            span1.transitionDuration = "0.5s"
            span1.backgroundColor = "rgba(138, 183, 190, 1)"

            span2.opacity = "1"
            span2.transitionDuration = "0.5s"

            span3.transform = "rotate(0deg)"
            span3.width = "2rem"
            span3.transitionDuration = "0.5s"
            span3.backgroundColor = "rgba(138, 183, 190, 1)"

        }
    }

    return(
        <>
            <div onClick={ menuSlide } id="menu-spans">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <menu id="menu-modal">
                <MenuContainer menuSlide={ menuSlide } />
            </menu>
        </>
    )
}

export default BurgerMenu