import React from 'react'
import { Link } from 'react-router-dom'
import './menu.css'

const MenuContainer = ({ menuSlide }) => {

    return(
        <div id="menu-container">
            <div className="menu-title-containers">
                <Link to="/"> 
                    <h2 onClick={ menuSlide } > Home </h2> 
                </Link>
            </div>
            <div className="menu-title-containers">
                <Link to="/about"> 
                    <h2 onClick={ menuSlide } > About </h2> 
                </Link>
            </div>
            {/* <div className="menu-title-containers">
                <Link to="/latest">
                    <h2 onClick={ menuSlide } >Latest</h2>
                </Link>
            </div> */}
            <div className="menu-title-containers">
                <Link to="/interiors">
                    <h2 onClick={ menuSlide } >Interiors</h2>
                </Link>
            </div>
            <div className="menu-title-containers">
                <Link to="/travel">
                    <h2 onClick={ menuSlide } >Travel</h2>
                </Link>
            </div>
            <div className="menu-title-containers">
                <Link to="/food&amp;drink">
                    <h2 onClick={ menuSlide } > Food &amp; Drink</h2>
                </Link>
            </div>
            <div className="menu-title-containers">
                <Link to="/presenter">
                    <h2 onClick={ menuSlide } >Presenter</h2>
                </Link>
            </div>
            <div className="menu-title-containers">
                <Link to="/contact">
                    <h2 onClick={ menuSlide } >Contact</h2>
                </Link>
            </div>
        </div>
    );

};

export default MenuContainer;