import './App.css';
import MainPage from './MainPage';
import axios from 'axios'

import React from 'react'

function App() {

  return (
    <>
      <MainPage />
    </>
  );
}

export default App;
