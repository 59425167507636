import React from 'react'
import { TwitterTimelineEmbed as Twitter } from 'react-twitter-embed'
import Spinner from '../Helpers/spinner'

import './social.css'

const SocialModal = ({ twitter, instagram, active, setActive }) =>{

    const handleExit = () => setActive( !active )
    if( !active ){
        return null
    }
    return(
        <div onClick={ handleExit } className='social-modal'>
            <Spinner />
            <span onClick={ handleExit } className="close-button"> <span className='close-button-path'> &nbsp;</span> </span>
            { twitter ? 
            <div id="twitter">
            <Twitter
                    sourceType="profile"
                    screenName="RosalindErskine"
                    />
            </div>
            : 
            
            <div id="instagram">
            {/* //     <a href='https://www.instagram.com/rosalind_erskine/' target = "_blank" rel = "noopener noreferrer" >
            //     <script src="https://snapwidget.com/js/snapwidget.js"></script>
            //         <iframe title='Instagrag widget' src="https://snapwidget.com/embed/893760" className="snapwidget-widget" allowtransparency="true" frameBorder="0" scrolling="no" style={{border: 'none'}}></iframe>
            //    </a> */}
                    <div data-mc-src="e0e6fcce-3fda-49e4-830c-e057be87d2c1#instagram" style={{height: '100%', width: '100%'}}></div>

                    
            </div>
            }
        </div>
    )
}

export default SocialModal