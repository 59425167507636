import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './landingImage.css'
import './interactiveImage.css'

import ArticleModal from '../articles/articleModal'

const InteractiveImage = ({
    pagePositionY, 
    pageWidth, 
    cssContainerId, 
    cssContainerClass, 
    cssImgContainerId, 
    imgSrc, 
    imgAlt, 
    scrollSpeed, 
    cssTextId, 
    textHoverContent,
    startingPosition,
    leftPosition,
    rightPosition,
    articlePages,
    articleLink,
    linkOut,
    routeLink,
    pageGap,
    media,
    blank,
    center,
    marqueeContent

}) => {

    const [ articleModalActive, setArticleModalActive ] = useState( false )
    
    const handleClick = () => {
        if( linkOut ){
            window.open(articleLink, '_blank')
        } else {
        setArticleModalActive( !articleModalActive )
    }}

    const tags = 
        <>
            {(!routeLink && articleModalActive) ?
                <ArticleModal  
                    click={ handleClick } 
                    pages={ articlePages }
                    link={ articleLink }
                    pageGap={ pageGap && pageGap }
                    media={ media }
                    center={ center }
                    marqueeContent={ marqueeContent }
                /> : null}
                <div onClick={ handleClick } id={ cssContainerId } className={ cssContainerClass + ` interactive-image ` +  ( blank ? 'mobile-spacer' : null ) }
                    style={{
                        transform: pageWidth < 768 ? null : `translateY(${-pagePositionY * scrollSpeed }px)`,
                        top: `${ startingPosition }rem`,
                        left: `${leftPosition}%`,
                        right: `${rightPosition}%`,
                        height: `${ blank && '1vh'}`,
                        boxShadow: `${ blank && 'none'}`
                    }}
                    >
                    <div 
                        id={ cssImgContainerId } 
                        className='i-container'
                        style={{
                            boxShadow: `${ blank && 'none'}`
                        }}
                        >
                        <img src={imgSrc} alt={ imgAlt } loading="lazy" />
                        <div id={ cssTextId } className="i-text">
                            <p>{ textHoverContent }</p>
                        </div>
                    </div>
                </div>
            </>

    return(
        <>
           { routeLink ? <Link to={'/' + routeLink } > { tags } </Link> : tags } 
        </>
    )
}

export default InteractiveImage