import React from 'react'
import './spinner.css'


const Spinner = () => {
    return(
        <div id='spinner'>🥃</div>
    )
}

export default Spinner