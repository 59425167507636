import React from 'react'
import './landingImage.css'

const LandingImage = ({ 
    imageSource,
    backgroundPosition,
    pagePositionY,
    pageWidth,
    h1Id, 
    h1Text, 
    displayH2,
    typeWriter, 
}) => {

    return(
        <div id="landing-image"
            style={{ 
                backgroundImage: `url(${imageSource})`,
                backgroundColor: 'black',
                backgroundPosition: `${ backgroundPosition }`, 
                backgroundSize: 'cover',
                opacity: `${ pagePositionY <= 0 ? 1 : pageWidth > 750 && (5/pagePositionY > 0.1 ? 5/pagePositionY : 0) }`,
                zIndex: `${ pagePositionY <= 0 ? 6 : 0 }`

            }}
        >
                <div className='header-wrapper'>
                    <h1 id={ h1Id } style={
                        pagePositionY <= 10 ? {opacity: 1} : { opacity: `${10/pagePositionY }`}
                    }>
                        { h1Text }
                    </h1>
                    {
                        displayH2 && 
                        <h2 style={
                            pagePositionY <= 10 ? {opacity: 1} : { opacity: `${10/pagePositionY }`}
                                }>
                            <span id="writer">{typeWriter}</span> - <span id="editor">Editor</span> - <span id="presenter">Presenter</span> - <span id="stylist">Interior Stylist</span>
                        </h2>
                    }
                </div>
        </div>
    )
}
export default LandingImage




