import React from 'react'

import InteractiveImage from '../image-components/interactiveImage'

import placeholder from '../../assets/barrels.jpeg'

import './features.css'
import LandingImage from '../image-components/landingImage'
import ScrollToTopArrow from '../Helpers/scrollToTopArrow'

const Features = ({ pagePositionY, pageWidth }) => {

    return(
        <>
            <ScrollToTopArrow />
            <LandingImage
                imageSource={ placeholder } 
                backgroundPosition='center bottom'
                pagePositionY={ pagePositionY }
                pageWidth={ pageWidth }
                h1Id='landing-text-features'
                h1Text='Features'
            />

            <div className='images'>
                <InteractiveImage
                    startingPosition={ 
                        pageWidth < 1400 ? 
                        pageWidth < 1100 ? 
                        324 : 
                        430: 
                        433 }
                        blank
                />
            </div>
        </>
    )
}

export default Features