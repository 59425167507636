import React, { useState } from 'react'
import './videoMarquee.css'


const VideoMarquee = ({ marqueeContent }) => {

    const [ vidPosition, setVidPosition ] = useState( 0 )

    const handleVidSwitch = (e, direction ) => {
        e.stopPropagation()
        if( direction === 'r' && (vidPosition >= 0 && vidPosition < marqueeContent.length - 1)) {
            setVidPosition( vidPosition + 1 )
        } else if (
            direction === 'l' && (vidPosition > 0 && vidPosition <= marqueeContent.length - 1)
        ) {
            setVidPosition( vidPosition - 1 )
        } else if (
            direction === 'r' && vidPosition === marqueeContent.length - 1
        ) {
            setVidPosition( 0 )
        } else (
            setVidPosition( marqueeContent.length - 1 )
        )
    }

    return(
        <>
            <span onClick={(e) => handleVidSwitch(e,'l')} className='button' id='img-back-button'><span className='left-arrow'>&nbsp;</span></span>
            { marqueeContent[vidPosition] }
            <span onClick={(e) => handleVidSwitch(e, 'r')} className='button' id='img-forward-button'> <span className='right-arrow'></span></span>
        </>
    )
}

export default VideoMarquee