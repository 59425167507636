import React from 'react'

const PageSpacer = ({ startingPosition }) => {
    return(
        <>
            <div className='mobile-spacer'
                style={{ 
                    top: `${ startingPosition }rem`,
                    height: '1vh',
                    position: 'absolute',
                    display: 'block',
                    padding: '1rem'
                    }}>
            </div>
        </>
    )
}

export default PageSpacer