import React from 'react'

const ContactForm = ({ close }) => {
    return(
        <>
            <div id='contact-modal' className='contact-form'>
                <span onClick={ close } className="close-button"> <span className='close-button-path'> &nbsp;</span> </span>
                <form className='contact-form' action='https://formsubmit.co/roz5001@googlemail.com' method="post">
                    <h2>Send Rosalind a message</h2>
                    <input type='hidden' name='_subject' value='New email from contact form' />
                    <input type="text" name="name" placeholder='Your name' required />
                    <input type="text" name="subject" placeholder='Subject' required />
                    <textarea type="text" rows='6' cols='30' name="message" placeholder='Your message' required />
                    <input type="email" name="email" placeholder='Your Email Address' required />
                    <input type='hidden' name='_next' value='https://www.rosalinderskine.com/contact' />
                    <input type="hidden" name="_autoresponse" value="Thank you, your message has been sent!" />
                    <button tabIndex='0' className='' type="submit">Send</button>
                </form>
            </div>
        </>
    )
}

export default ContactForm