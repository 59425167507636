import React, {useEffect} from 'react'
import Spinner from '../Helpers/spinner'
import VideoMarquee from '../videoMarquee/videoMarquee'
import './article.css'


const ArticleModal = ({ click, pages, link, pageGap, media, center, marqueeContent }) => {

    const pageImages = pages && pages.map( ( page, index ) => { return(
        <img onDragStart={(e) => e.preventDefault()} key={ index } className={ `article-pages ${ pageGap && 'extra-width' }` } style={ pageGap && { margin: '0.5vw' }} src={ page } alt="" 
        loading='lazy'
        />
    )}
    )
    let slider;
    let isDown = false
    useEffect(() => {
        if(pages?.length > 1){
            slider = document.querySelector('#grab-scroll')
            slider.style.cursor = "grab"
            pages && pageScroll()
        }
    }, [isDown])

    let startX
    let scrollLeft;
    const pageScroll = () => {
        
        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.style.cursor = "grabbing"
            startX = e.pageX - slider.offsetLeft
            scrollLeft = slider.scrollLeft
        })
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.style.cursor = "grab"
        })
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.style.cursor = 'grab'
        })
        slider.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            e.preventDefault();
            slider.style.cursor = "grabbing"

            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX)*3;
            slider.scrollLeft = scrollLeft - walk
        })
    }


    return(
        <div /*onClick={ !marqueeContent ? click : undefined }*/ id="article-modal">
            <Spinner />
            <span style={{zIndex: '10000'}} onClick={ click } className="close-button"> <span className='close-button-path'> &nbsp;</span> </span>
            <div onClick={click} className={`img-modal-wrapper ${pages ? pages.length === 1 ? ' single-page' : '' : ''}`}
            style={ ( center || link )  && { paddingLeft: '0', marginRight: '0' }}
            >
                { marqueeContent && <VideoMarquee marqueeContent={ marqueeContent } />}
                { pages && 
                <div onClick={(e) => e.stopPropagation()} id='grab-scroll'>
                    {pageImages} 
                </div>
                }
                {link && <iframe
                    title={ link } 
                    src={ link } 
                    style={{    
                        width: '90vw',
                        margin: '5vw', 
                        height: '90%', 
                        backgroundColor: 'white',
                        boxShadow: '0 0 20px 8px black'    
                    }} 
                    frameBorder='none'/> }
                { media }
            </div>
        </div>
    )
}

export default ArticleModal