import React from 'react'
import '../../App.css'
import './landingPage.css'
import rozProfile from '../../assets/rozHome.jpg'
import sanFran from '../../assets/SanFran.JPG'
import food from '../../assets/dessert.jpg'
import tv from '../../assets/tv.jpg'
import Typewriter from 'typewriter-effect'
import interior from '../../assets/Interiors.png'
import InteractiveImage from '../image-components/interactiveImage'
import LandingImage from '../image-components/landingImage'
import ScrollToTopArrow from '../Helpers/scrollToTopArrow'
import PageSpacer from '../image-components/pageSpacer'

const PageElement = ({ pagePositionY, pageWidth }) => {
    
    const typeWriter = 
        <Typewriter onInit={(typewriter) => {
            typewriter.typeString('Writer')
            .pauseFor( 25 )
            .start()
        }} />

    return (
        <>
            <ScrollToTopArrow />
            <LandingImage 
                imageSource={ rozProfile } 
                backgroundPosition='15% 30%'
                pagePositionY={ pagePositionY }
                h1Id='roz-name'
                h1Text='Rosalind Erskine' 
                displayH2
                typeWriter={ typeWriter }
                pageWidth={ pageWidth }
            />
            <div className="images">
                    <InteractiveImage
                        pagePositionY={ pagePositionY } 
                        pageWidth={ pageWidth } 
                        cssContainerId="home1"
                        cssContainerClass="mobile"
                        cssImgContainerId="h1-container"
                        imgSrc={ interior }
                        imgAlt="interiors magazine"
                        scrollSpeed = '2'
                        cssTextId='h1-text'
                        textHoverContent='Interiors'
                        startingPosition={ pageWidth < 1100 ? 100 : 80 }
                        leftPosition={ 10 }
                        routeLink='interiors'
                    />
                    <InteractiveImage
                        pagePositionY={ pagePositionY } 
                        pageWidth={ pageWidth } 
                        cssContainerId="home2"
                        cssContainerClass="mobile"
                        cssImgContainerId="h2-container"
                        imgSrc={ sanFran }
                        imgAlt="San Francisco steep street"
                        scrollSpeed = '1.3'
                        cssTextId='h2-text'
                        textHoverContent='Travel'
                        startingPosition={ pageWidth < 1100 ? 105 : 85 }
                        rightPosition={ 5 }
                        routeLink='travel'
                    />
                    <InteractiveImage
                        pagePositionY={ pagePositionY } 
                        pageWidth={ pageWidth } 
                        cssContainerId="home3"
                        cssContainerClass="mobile"
                        cssImgContainerId="h3-container"
                        imgSrc={ food }
                        imgAlt="Fine dining chocolate dessert"
                        scrollSpeed = '1.1'
                        cssTextId='h3-text'
                        textHoverContent={ 'Food & Drink' }
                        startingPosition={ pageWidth < 1100 ? 150 : 140 }
                        leftPosition={ 5 }
                        routeLink='food&amp;drink'  
                    />
                    <InteractiveImage
                        pagePositionY={ pagePositionY } 
                        pageWidth={ pageWidth } 
                        cssContainerId="home4"
                        cssContainerClass="mobile"
                        cssImgContainerId="h4-container"
                        imgSrc={ tv }
                        imgAlt="Rosalind on TV"
                        scrollSpeed = '1.3'
                        cssTextId='h4-text'
                        textHoverContent={'Presenter'}
                        startingPosition={ pageWidth < 1100 ? 255 : 245 }
                        leftPosition={ 0 }
                        routeLink='presenter'  
                    />

                    <PageSpacer 
                        startingPosition={ 
                            pageWidth < 1400 ? 
                            pageWidth < 1100 ? 
                            127 : 
                            127 : 
                            132 }
                    />
                </div>
        </>
    )

}

export default PageElement;