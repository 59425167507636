import React from 'react'
import InteractiveImage from '../image-components/interactiveImage'
import LandingImage from '../image-components/landingImage'

import './travel.css'

import t1 from '../../assets/capilano.JPG'

import t1pg1 from '../../assets/Travel/trav1-1.jpg'

import t2 from '../../assets/Travel/trav2/Trav2thumb.jpg'
import t2pg1 from'../../assets/Travel/trav2/trav2-1.jpg'
import t2pg2 from'../../assets/Travel/trav2/trav2-2.jpg'
import t2pg3 from'../../assets/Travel/trav2/trav2-3.jpg'
import t2pg4 from'../../assets/Travel/trav2/trav2-4.jpg'

import t3 from '../../assets/Travel/trav3/Trav3thumb.jpg'
import t3pg1 from '../../assets/Travel/trav3/trav3-1.jpg'

import t4 from '../../assets/Travel/trav4/Trav4thumb.jpg'
import t4pg1 from '../../assets/Travel/trav4/trav4-1.jpg'
import t4pg2 from '../../assets/Travel/trav4/trav4-2.jpg'
import t4pg3 from '../../assets/Travel/trav4/trav4-3.jpg'

import t5 from '../../assets/Travel/trav5/trav5thumb.jpg'
import t5pg1 from '../../assets/Travel/trav5/trav5-1.jpg'

import t6 from '../../assets/Travel/trav6/trav6thumb.jpg'
import t6pg1 from '../../assets/Travel/trav6/trav6-1.jpg'

import t7 from '../../assets/Travel/trav7/trav7thumb.jpg'
import t7pg1 from '../../assets/Travel/trav7/trav7-1.jpg'

import t8 from '../../assets/Travel/trav8/trav8thumb.jpg'
import t8pg1 from '../../assets/Travel/trav8/trav8-1.jpg'

import t9 from '../../assets/Travel/trav9/trav9thumb.jpg'
import t9pg1 from '../../assets/Travel/trav9/trav9-1.jpg'

import t10 from '../../assets/Travel/trav10/trav10thumb.jpg'
import t10pg1 from '../../assets/Travel/trav10/trav10-1.jpg'
import t10pg2 from '../../assets/Travel/trav10/trav10-2.jpg'

import t11 from '../../assets/Travel/trav11/trav11thumb.jpg'
import t11pg1 from '../../assets/Travel/trav11/trav11.jpg'

import t12 from '../../assets/Travel/trav12/trav12thumb.jpg'

import t13 from '../../assets/Travel/trav13/trav13thumb.jpg'


import ScrollToTopArrow from '../Helpers/scrollToTopArrow'


const Travel = ({ pagePositionY, pageWidth }) => {

    return(
        <>
        <ScrollToTopArrow />
            <LandingImage
                    imageSource={ t1 }
                    backgroundPosition='75% 60%'
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth }
                    h1Id='landing-text-travel'
                    h1Text='Travel'
                />
            <div className='images'>
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav1"
                    cssContainerClass="mobile"
                    cssImgContainerId="t1-container"
                    imgSrc={ t1pg1 }
                    imgAlt="Staircase at the Fife Arms"
                    scrollSpeed = '2.2'
                    cssTextId='t1-text'
                    textHoverContent='Harper&apos;s Bazaar'
                    startingPosition={ pageWidth < 1100 ? 90 : 90 }
                    leftPosition={ 5 }
                    articleLink={ 'https://www.harpersbazaararabia.com/interiors/design/this-hotel-in-scotland-is-an-art-lovers-dream' }
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav2"
                    cssContainerClass="mobile"
                    cssImgContainerId="t2-container"
                    imgSrc={ t2 }
                    imgAlt="Lobby of Waldorf Astoria, Herengracht, Amsterdam"
                    scrollSpeed = '1.3'
                    cssTextId='t2-text'
                    textHoverContent='Emirates Home'
                    startingPosition={ pageWidth < 1100 ? 80 : 80 }
                    rightPosition={ 35 }
                    articlePages={[ t2pg1, t2pg2, t2pg3, t2pg4 ]}
                />
                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav3"
                    cssContainerClass="mobile"
                    cssImgContainerId="t3-container"
                    imgSrc={ t5 }
                    imgAlt="Notre-Dame Cathedral"
                    scrollSpeed = '1'
                    cssTextId='t3-text'
                    textHoverContent='Scotsman Travel'
                    startingPosition={ pageWidth < 1100 ? 76 : 76 }
                    rightPosition={ 5 }
                    articlePages={[ t5pg1 ]}
                />
                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav4"
                    cssContainerClass="mobile"
                    cssImgContainerId="t4-container"
                    imgSrc={ t4 }
                    imgAlt='Clear blue waters at the Bung Raya, Island Resort'
                    scrollSpeed = '2'
                    cssTextId='t4-text'
                    textHoverContent='Emirates Home'
                    startingPosition={ pageWidth < 1100 ? 180 : 200 }
                    leftPosition={ 5 }
                    articlePages={[ t4pg1, t4pg2, t4pg3 ]}
                />
                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav5"
                    cssContainerClass="mobile"
                    cssImgContainerId="t5-container"
                    imgSrc={ t3 }
                    imgAlt="Colour palette of blue shades"
                    scrollSpeed = '1.4'
                    cssTextId='t5-text'
                    textHoverContent='Emirates Home'
                    startingPosition={ pageWidth < 1100 ? 180 : 230 }
                    leftPosition={ 10 }
                    articlePages={[ t3pg1 ]}
                />
                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav6"
                    cssContainerClass="mobile"
                    cssImgContainerId="t6-container"
                    imgSrc={ t6 }
                    imgAlt='Ski-lifts at Big White'
                    scrollSpeed = '1'
                    cssTextId='t6-text'
                    textHoverContent='Scotsman Travel'
                    startingPosition={ pageWidth < 1100 ? 180 : 260 }
                    leftPosition={ 5 }
                    articlePages={[ t6pg1 ]}
                />
                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav7"
                    cssContainerClass="mobile"
                    cssImgContainerId="t7-container"
                    imgSrc={ t7 }
                    imgAlt='Gondolas on the canal during the Waterfire Festival'
                    scrollSpeed = '1.6'
                    cssTextId='t7-text'
                    textHoverContent='Scotsman Travel'
                    startingPosition={ pageWidth < 1100 ? 280 : 460 }
                    rightPosition={ 5 }
                    articlePages={[ t7pg1 ]}
                />
                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav8"
                    cssContainerClass="mobile"
                    cssImgContainerId="t8-container"
                    imgSrc={ t8 }
                    imgAlt='The Forbidden City'
                    scrollSpeed = '2.2'
                    cssTextId='t8-text'
                    textHoverContent='Scotsman Travel'
                    startingPosition={ pageWidth < 1100 ? 440 : 680 }
                    leftPosition={ 5 }
                    articlePages={[ t8pg1 ]}
                />

                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav9"
                    cssContainerClass="mobile"
                    cssImgContainerId="t9-container"
                    imgSrc={ t9 }
                    imgAlt='Kapaleeshwarar temple'
                    scrollSpeed = '1'
                    cssTextId='t9-text'
                    textHoverContent='Scotsman Travel'
                    startingPosition={ pageWidth < 1100 ? 320 : 470 }
                    rightPosition={ 17 }
                    articlePages={[ t9pg1 ]}
                />

                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav10"
                    cssContainerClass="mobile"
                    cssImgContainerId="t10-container"
                    imgSrc={ t10 }
                    imgAlt='Place de la Bourse reflected in Miroir d&apos;eau'
                    scrollSpeed = '1.4'
                    cssTextId='t10-text'
                    textHoverContent='The Scotsman Magazine'
                    startingPosition={ pageWidth < 1100 ? 350 : 530 }
                    rightPosition={ 17 }
                    articlePages={[ t10pg1, t10pg2 ]}
                />

                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav11"
                    cssContainerClass="mobile"
                    cssImgContainerId="t11-container"
                    imgSrc={ t11 }
                    imgAlt='Article headline'
                    scrollSpeed = '0.7'
                    cssTextId='t11-text'
                    textHoverContent='The Sunday Post'
                    startingPosition={ pageWidth < 1100 ? 300 : 450 }
                    rightPosition={ 5 }
                    articlePages={[ t11pg1 ]}
                />

                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav12"
                    cssContainerClass="mobile"
                    cssImgContainerId="t12-container"
                    imgSrc={ t12 }
                    imgAlt='Cockapoo'
                    scrollSpeed = '2.4'
                    cssTextId='t12-text'
                    textHoverContent='The Sunday Post'
                    startingPosition={ pageWidth < 1100 ? 700 : 1050 }
                    leftPosition={ 5 }
                    linkOut
                    articleLink={ 'https://www.sundaypost.com/fp/travel-enjoy-the-stunning-scenery-of-loch-ness-plus-super-food-youll-lovat/' }
                />
                <InteractiveImage
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="trav13"
                    cssContainerClass="mobile"
                    cssImgContainerId="t13-container"
                    imgSrc={ t13 }
                    imgAlt='Colourful houses on the water front at Deauville'
                    scrollSpeed = '1.5'
                    cssTextId='t13-text'
                    textHoverContent='The Sunday Post'
                    startingPosition={ pageWidth < 1100 ? 540 : 815 }
                    rightPosition={ 15 }
                    linkOut
                    articleLink={ 'https://www.sundaypost.com/fp/chanel-or-high-water-fashionistas-by-the-sea-in-beautiful-normandy/' }
                />


                <InteractiveImage
                    startingPosition={ 
                        pageWidth < 1400 ? 
                        pageWidth < 1100 ? 
                        187: 
                        259 : 
                        260 }
                        blank
                />

            </div>
        </>
    )
}

export default Travel