import React, { useState } from 'react'

import linked from '../../assets/linkedin1.svg'
import twitter from '../../assets/twitter-4.svg'
import instagram from '../../assets/instagram-2-1.svg'
import email from '../../assets/paper-plane.svg'

import './contact.css'
import ContactForm from './contactForm/contactForm'
import SocialModal from '../social/socialModal'

const ContactPage = () => {

    const [ contactFormVisible, setContactFormVisible ] = useState( false )
    const [ socialPlatform, setSocialPlatform ] = useState()
    const [ socialModalActive, setSocialModalActive ] = useState( false )
    
    const openSocialModal = ( platform ) => {
        setSocialModalActive( true)
        if( platform === 't'){
            setSocialPlatform( 't' )
        } else if ( platform === 'i'){
            setSocialPlatform( 'i' )
        }
    }

    const handleClick = () => {
        setContactFormVisible( !contactFormVisible )
    }

    return(
        <>
            <div id='contacts-wrapper'>

                { contactFormVisible && <ContactForm close={() => handleClick()} /> }
                <div id='contacts'>
                    <img onClick={ handleClick } className='contact-logo' alt='' src={ email } />
                    <a href='https://www.linkedin.com/in/rosalind-erskine-8876212a/' target='_blank' rel='noreferrer'>
                        <img className='contact-logo'  alt='' src={ linked } />
                    </a>
                    <img onClick={ () => openSocialModal( 't' ) } className='contact-logo' alt='' src={ twitter } />
                    <img onClick={ () => openSocialModal( 'i' ) } className='contact-logo' alt='' src={ instagram } />
                </div>
                <a className='designed' href='https://www.linkedin.com/in/craig-r-mcleod/' target='_blank' rel='noreferrer'>
                    Web design by Craig McLeod ©
                </a>
            </div>
        <SocialModal setActive={ setSocialModalActive } active={ socialModalActive } twitter={ socialPlatform === 't' } instagram={ socialPlatform === 'i'} />
        </>
    )
}

export default ContactPage