import React from 'react'

import './presenter.css'

import InteractiveImage from '../image-components/interactiveImage'
import LandingImage from '../image-components/landingImage'
import presLanding from '../../assets/presenter/presenter.jpg'
import p1 from '../../assets/presenter/scran.jpg'
import p2 from '../../assets/presenter/p2.jpg'
import p3 from '../../assets/presenter/p3.jpg'
import ScrollToTopArrow from '../Helpers/scrollToTopArrow'

const Presenter = ({ pagePositionY, pageWidth }) => {

    const scranIframe = <iframe className='ext-iframe' id='scran-podcast' title='Scran podcast'src="https://open.spotify.com/embed-podcast/show/78FBCK1yhoQV5HMMG6Z6WF" width="60%" height="232" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    const bbcReelIframe = <iframe className='ext-iframe' id='bbc-reel' title='BBC Great Food Guys Reel ' width="560" height="315" src="https://www.youtube.com/embed/DHcUNdpuD5E" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    const twb1 = <iframe className='ext-iframe smaller' title='The Whiskey Barrel 1' width="560" height="315" src="https://www.youtube.com/embed/FhHOzlquFjA" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>         
    const twb2 = <iframe className='ext-iframe smaller' title='The Whiskey Barrel 2' width="560" height="315" src="https://www.youtube.com/embed/kBd9l_9K1oU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    const twb3 = <iframe className='ext-iframe smaller' title='The Whiskey Barrel 3' width="560" height="315" src="https://www.youtube.com/embed/sfu5TY4DWp8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    const twb4 = <iframe className='ext-iframe smaller' title='The Whiskey Barrel 4' width="560" height="315" src="https://www.youtube.com/embed/irjEFQOAKsc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    const twb5 = <iframe className='ext-iframe smaller' title='The Whiskey Barrel 5' width="560" height="315" src="https://www.youtube.com/embed/kMAErvWzv14" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    const twb6 = <iframe className='ext-iframe smaller' title='The Whiskey Barrel 6' width="560" height="315" src="https://www.youtube.com/embed/VECrWYf1tto" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>


    return(
        <>
        <ScrollToTopArrow />

            <LandingImage
                        imageSource={ presLanding }
                        backgroundPosition='center'
                        pagePositionY={ pagePositionY }
                        pageWidth={ pageWidth }
                        h1Id='landing-text-presenter'
                        h1Text='Presenter'
                    />
                <div className='images'>
                    <InteractiveImage 
                        pagePositionY={ pagePositionY } 
                        pageWidth={ pageWidth } 
                        cssContainerId="pres1"
                        cssContainerClass="mobile"
                        cssImgContainerId="p1-container"
                        imgSrc={ p1 }
                        imgAlt='Scran logo'
                        scrollSpeed = '0.6'
                        cssTextId='p1-text'
                        textHoverContent='Scran Podcast'
                        startingPosition={ pageWidth < 1100 ? 75 : 80 }
                        leftPosition={ 5 }
                        media={ scranIframe }
                    />
                    <InteractiveImage 
                        pagePositionY={ pagePositionY } 
                        pageWidth={ pageWidth } 
                        cssContainerId="pres2"
                        cssContainerClass="mobile"
                        cssImgContainerId="p2-container"
                        imgSrc={ p2 }
                        imgAlt='Scran logo'
                        scrollSpeed = '1'
                        cssTextId='p2-text'
                        textHoverContent='BBC Great Food Guys'
                        startingPosition={ pageWidth < 1100 ? 130 : 140 }
                        rightPosition={ 5 }
                        media={ bbcReelIframe }
                        center={ true }
                    />
                    <InteractiveImage 
                        pagePositionY={ pagePositionY } 
                        pageWidth={ pageWidth } 
                        cssContainerId="pres3"
                        cssContainerClass="mobile"
                        cssImgContainerId="p3-container"
                        imgSrc={ p3 }
                        imgAlt='Scran logo'
                        scrollSpeed = '0.2'
                        cssTextId='p3-text'
                        textHoverContent='The Whiskey Barrel'
                        startingPosition={ pageWidth < 1100 ? 105 : 112 }
                        leftPosition={ 15 }
                        center={ true }
                        marqueeContent={[ twb1, twb2, twb3, twb4, twb5, twb6 ]}
                    />

                    <InteractiveImage
                    startingPosition={ 
                        pageWidth < 1400 ? 
                        pageWidth < 1100 ? 
                        110 : 
                        118 : 
                        126 }
                />
                </div>
        </>
    )
}

export default Presenter