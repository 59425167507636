import React from 'react'

import i1 from '../../assets/Interiors/IntsThumb1.png'
import i2 from '../../assets/Interiors/IntsThumb2.png'
import i3 from '../../assets/Interiors/IntsThumb3.png'
import i4 from '../../assets/Interiors/IntsThumb4.png'
import i5 from '../../assets/Interiors/Ints5.png'
import i6 from '../../assets/Interiors/IntsThumb6.png'
// import i7 from '../../assets/Interiors/IntsThumb7.png'
import i8 from '../../assets/Interiors/IntsThumb8.png'
import i9 from '../../assets/Interiors/IntsThumb9.png'
import i10 from '../../assets/Interiors/IntsThumb10.jpg'
import i11 from '../../assets/Interiors/IntThumb11.png'
import i13 from '../../assets/Interiors/Intsthumb13.png'
import i14 from '../../assets/Interiors/IntsThumb14.png'
import i15 from '../../assets/Interiors/IntsThumb15.png'
import i16 from '../../assets/Interiors/Ints16/int16.png'
import i17 from '../../assets/Interiors/Ints17/Int17.png'
import i18 from '../../assets/Interiors/Int18.png'
import i19 from '../../assets/Interiors/Int19.png'
import i20 from '../../assets/Interiors/IntsThumb20.jpg'

import InteractiveImage from '../image-components/interactiveImage'
import i1pg1 from '../../assets/Interiors/Ints1/Ints1-1.jpg'
import i1pg2 from '../../assets/Interiors/Ints1/Ints1-2.jpg'
import i1pg3 from '../../assets/Interiors/Ints1/Ints1-3.jpg'
import i1pg4 from '../../assets/Interiors/Ints1/Ints1-4.jpg'
import i2pg1 from '../../assets/Interiors/Ints2/Ints2-1.jpg'
import i2pg2 from '../../assets/Interiors/Ints2/Ints2-2.jpg'
import i2pg3 from '../../assets/Interiors/Ints2/Ints2-3.jpg'
import i2pg4 from '../../assets/Interiors/Ints2/Ints2-4.jpg'
import i3pg1 from '../../assets/Interiors/Ints3/Ints3-1.jpg'
import i3pg2 from '../../assets/Interiors/Ints3/Ints3-2.jpg'
import i3pg3 from '../../assets/Interiors/Ints3/Ints3-3.jpg'
import i3pg4 from '../../assets/Interiors/Ints3/Ints3-4.jpg'
import i3pg5 from '../../assets/Interiors/Ints3/Ints3-5.jpg'
import i3pg6 from '../../assets/Interiors/Ints3/Ints3-6.jpg'
import i4pg1 from '../../assets/Interiors/Ints4/RH 4 McCallister - Boho, London_pdf_1-1.jpg'
import i4pg2 from '../../assets/Interiors/Ints4/RH 4 McCallister - Boho, London_pdf_2-1.jpg'
import i4pg3 from '../../assets/Interiors/Ints4/RH 4 McCallister - Boho, London_pdf_3-1.jpg'
import i4pg4 from '../../assets/Interiors/Ints4/RH 4 McCallister - Boho, London_pdf_4-1.jpg'
import i4pg5 from '../../assets/Interiors/Ints4/RH 4 McCallister - Boho, London_pdf_5-1.jpg'
import i4pg6 from '../../assets/Interiors/Ints4/RH 4 McCallister - Boho, London_pdf_6-1.jpg'
import i4pg8 from '../../assets/Interiors/Ints4/RH 4 McCallister - Boho, London_pdf_8-1.jpg'
import i5pg1 from '../../assets/Interiors/Ints5/Ints5-1.jpg'
import i12pg1 from '../../assets/Interiors/Ints12/Ints12-1.jpg'
import i12pg2 from '../../assets/Interiors/Ints12/Ints12-2.jpg'
import i12pg3 from '../../assets/Interiors/Ints12/Ints12-3.jpg'
import i10pg1 from '../../assets/Interiors/Ints10/output-onlinepngtools.png'
import i10pg2 from '../../assets/Interiors/Ints10/output-onlinepngtools-2.png'
import i10pg3 from '../../assets/Interiors/Ints10/output-onlinepngtools-3.png'
import i10pg4 from '../../assets/Interiors/Ints10/output-onlinepngtools-4.png'
import i10pg5 from '../../assets/Interiors/Ints10/output-onlinepngtools-5.png'
import i10pg6 from '../../assets/Interiors/Ints10/output-onlinepngtools-6.png'
import i10pg7 from '../../assets/Interiors/Ints10/output-onlinepngtools-7.png'
import i10pg8 from '../../assets/Interiors/Ints10/output-onlinepngtools-8.png'
import i10pg9 from '../../assets/Interiors/Ints10/output-onlinepngtools-9.png'

import i13pg1 from '../../assets/Interiors/Ints13/Ints13-1.png'
import i13pg2 from '../../assets/Interiors/Ints13/Ints13-2.png'
import i13pg3 from '../../assets/Interiors/Ints13/Ints13-3.png'

import i14pg1 from '../../assets/Interiors/Ints14/Ints14-1.png'

import i15pg1 from '../../assets/Interiors/Ints15/Ints15-1.png'
import i15pg2 from '../../assets/Interiors/Ints15/Ints15-2.png'

import i16pg1 from '../../assets/Interiors/Ints16/Int16a.jpg'

import i17pg1 from '../../assets/Interiors/Ints17/Int17a.jpg'

import i18pg1 from '../../assets/Interiors/Ints18/Ints18-1.png'
import i18pg2 from '../../assets/Interiors/Ints18/Ints18-2.png'
import i18pg3 from '../../assets/Interiors/Ints18/Ints18-3.png'
import i18pg4 from '../../assets/Interiors/Ints18/Ints18-4.png'

import i19pg1 from '../../assets/Interiors/Ints19/Ints19-1.png'
import i19pg2 from '../../assets/Interiors/Ints19/Ints19-2.png'
import i19pg3 from '../../assets/Interiors/Ints19/Ints19-3.png'
import i19pg4 from '../../assets/Interiors/Ints19/Ints19-4.png'

import i20pg1 from '../../assets/Interiors/Ints20/Ints20.jpg'

import './interiors.css'
import LandingImage from '../image-components/landingImage'
import ScrollToTopArrow from '../Helpers/scrollToTopArrow'

const Interiors = ({ pagePositionY, pageWidth }) => {

    return(
        <>
            <ScrollToTopArrow />
            <LandingImage
                imageSource={ i5 } 
                backgroundPosition='center bottom'
                pagePositionY={ pagePositionY }
                pageWidth={ pageWidth }
                h1Id='landing-text-interiors'
                h1Text='Interiors'
            />

            <div className='images'>
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints1"
                    cssContainerClass="mobile"
                    cssImgContainerId="i1-container"
                    imgSrc={ i1 }
                    imgAlt="interiors magazine"
                    scrollSpeed = '2.2'
                    cssTextId='i1-text'
                    textHoverContent='Harper&apos;s Bazaar'
                    startingPosition={ pageWidth  < 1100 ? 105 : 130 }
                    leftPosition={ 10 }
                    articlePages={[ i1pg1, i1pg2, i1pg3, i1pg4 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints2"
                    cssContainerClass="mobile"
                    cssImgContainerId="i2-container"
                    imgSrc={ i2 }
                    imgAlt="stylish living room"
                    scrollSpeed = '0.7'
                    cssTextId='i2-text'
                    textHoverContent='Harper&apos;s Bazaar'
                    startingPosition={ pageWidth < 1100 ? 150 : 160 }
                    rightPosition={ 5 }
                    articlePages={[ i2pg1, i2pg2, i2pg3, i2pg4 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints3"
                    cssContainerClass="mobile"
                    cssImgContainerId="i3-container"
                    imgSrc={ i3 }
                    imgAlt="steel framed property"
                    scrollSpeed = '1.1'
                    cssTextId='i3-text'
                    textHoverContent='25 Beautiful Homes'
                    startingPosition={ pageWidth < 1100 ? 250 : 300 }
                    leftPosition={ 8 }
                    articlePages={[ i3pg1, i3pg2, i3pg3, i3pg4, i3pg5, i3pg6 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints4"
                    cssContainerClass="mobile"
                    cssImgContainerId="i4-container"
                    imgSrc={ i4 }
                    imgAlt="bold and beautiful magazine headline"
                    scrollSpeed = '0.2'
                    cssTextId='i4-text'
                    textHoverContent='House Beautiful'
                    startingPosition={ pageWidth < 1100 ? 150 : 170 }
                    rightPosition={ 5 }
                    articlePages={[ i4pg1, i4pg2, i4pg3, i4pg4, i4pg5, i4pg6, i4pg8 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints5"
                    cssContainerClass="mobile"
                    cssImgContainerId="i5-container"
                    imgSrc={ i5 }
                    imgAlt="large stylish living room"
                    scrollSpeed = '0.9'
                    cssTextId='i5-text'
                    textHoverContent='Harper&apos;s Bazaar'
                    startingPosition={ pageWidth < 1100 ? 340 : 400 }
                    leftPosition={ 10 }
                    articlePages={[ i5pg1 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints6"
                    cssContainerClass="mobile"
                    cssImgContainerId="i6-container"
                    imgSrc={ i6 }
                    imgAlt="unusual ceramics on bookshelf"
                    scrollSpeed = '1.1'
                    cssTextId='i6-text'
                    textHoverContent='Heart Home'
                    startingPosition={ pageWidth < 1100 ? 475 : 595 }
                    leftPosition={ 10 }
                    articleLink={ 'https://www.hearthomemag.co.uk/blog/second-chance' }
                />
                {/* <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints7"
                    cssContainerClass="mobile"
                    cssImgContainerId="i7-container"
                    imgSrc={ i7 }
                    imgAlt="vibrant purple wall lampshade and pictures"
                    scrollSpeed = '1.4'
                    cssTextId='i7-text'
                    textHoverContent='The Guardian'
                    startingPosition={ pageWidth < 1100 ? 555 : 705 }
                    rightPosition={ 10 }
                    linkOut
                    articleLink={ 'https://www.theguardian.com/lifeandstyle/2020/dec/26/birds-of-a-feather-chinoiserie-in-a-home-in-east-lothian' }
                /> */}
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints8"
                    cssContainerClass="mobile"
                    cssImgContainerId="i8-container"
                    imgSrc={ i8 }
                    imgAlt="bookshelf organised by colour"
                    scrollSpeed = '1.6'
                    cssTextId='i8-text'
                    textHoverContent='The Scotsman'
                    startingPosition={ pageWidth < 1100 ? 640 : 830 }
                    rightPosition={ 6 }
                    linkOut
                    articleLink={ 'https://www.scotsman.com/lifestyle/interiors-morningside-mews-house-1567099' }
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints9"
                    cssContainerClass="mobile"
                    cssImgContainerId="i9-container"
                    imgSrc={ i9 }
                    imgAlt="large stylish living room"
                    scrollSpeed = '1.8'
                    cssTextId='i9-text'
                    textHoverContent='The Scotsman'
                    startingPosition={ pageWidth < 1100 ? 760 : 965 }
                    rightPosition={ 5 }
                    linkOut
                    articleLink={ 'https://www.scotsman.com/lifestyle/interiors-logie-country-house-back-ashes-2467926' }
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY }
                    pageWidth={ pageWidth } 
                    cssContainerId="ints10"
                    cssContainerClass="mobile"
                    cssImgContainerId="i10-container"
                    imgSrc={ i10 }
                    imgAlt="colourful bedroom with Bluebellgray bedclothes"
                    scrollSpeed = '2'
                    cssTextId='i10-text'
                    textHoverContent='Bluebellgray Styling'
                    startingPosition={ pageWidth < 1100 ? 900 : 1150 }
                    leftPosition={ 10 }
                    pageGap
                    articlePages={[ i10pg1, i10pg2, i10pg3, i10pg4, i10pg5, i10pg6, i10pg7, i10pg8, i10pg9 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints11"
                    cssContainerClass="mobile"
                    cssImgContainerId="i11-container"
                    imgSrc={ i11 }
                    imgAlt="Ceramic jug with black and white stag drawing"
                    scrollSpeed = '2.3'
                    cssTextId='i11-text'
                    textHoverContent='Heart Home'
                    startingPosition={ pageWidth < 1100 ? 1020 : 1320 }
                    rightPosition={ 10 }
                    articleLink={ 'https://issuu.com/hearthomemag/docs/heart_home_magazine_issue_6/131' }
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints12"
                    cssContainerClass="mobile"
                    cssImgContainerId="i12-container"
                    imgSrc={ i12pg1 }
                    imgAlt="Editors letter for Emirates Home magazine"
                    scrollSpeed = '2.3'
                    cssTextId='i12-text'
                    textHoverContent='Emirates Home'
                    startingPosition={ pageWidth < 1100 ? 1070 : 1400 }
                    rightPosition={ 25 }
                    articlePages={[ i12pg1, i12pg2, i12pg3 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints13"
                    cssContainerClass="mobile"
                    cssImgContainerId="i13-container"
                    imgSrc={ i13 }
                    imgAlt="Magazine headline reading 'Home for life'"
                    scrollSpeed = '1.5'
                    cssTextId='i13-text'
                    textHoverContent='Emirates Home'
                    startingPosition={ pageWidth < 1100 ? 840 : 1110 }
                    leftPosition={ 5 }
                    articlePages={[ i13pg1, i13pg2, i13pg3 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints14"
                    cssContainerClass="mobile"
                    cssImgContainerId="i14-container"
                    imgSrc={ i14 }
                    imgAlt="abstract cropped section of Emirates Home cover"
                    scrollSpeed = '1.7'
                    cssTextId='i14-text'
                    textHoverContent='Emirates Home'
                    startingPosition={ pageWidth < 1100 ? 940 : 1270 }
                    rightPosition={ 5 }
                    articlePages={[ i14pg1 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints15"
                    cssContainerClass="mobile"
                    cssImgContainerId="i15-container"
                    imgSrc={ i15 }
                    imgAlt="Decorative painting"
                    scrollSpeed = '1.2'
                    cssTextId='i15-text'
                    textHoverContent='Emirates Home'
                    startingPosition={ pageWidth < 1100 ? 770 : 1030 }
                    leftPosition={ 5 }
                    articlePages={[ i15pg1, i15pg2 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints16"
                    cssContainerClass="mobile"
                    cssImgContainerId="i16-container"
                    imgSrc={ i16 }
                    imgAlt="Family pictured in front of accessible renovated home"
                    scrollSpeed = '0.8'
                    cssTextId='i16-text'
                    textHoverContent='Edinburgh Evening News'
                    startingPosition={ pageWidth < 1100 ? 660 : 900 }
                    rightPosition={ 5 }
                    articlePages={[ i16pg1 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints17"
                    cssContainerClass="mobile"
                    cssImgContainerId="i17-container"
                    imgSrc={ i17 }
                    imgAlt="Stylish room"
                    scrollSpeed = '1.5'
                    cssTextId='i17-text'
                    textHoverContent='Observer Magazine'
                    startingPosition={ pageWidth < 1100 ? 1000 : 1390 }
                    leftPosition={ 5 }
                    articlePages={[ i17pg1 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints18"
                    cssContainerClass="mobile"
                    cssImgContainerId="i18-container"
                    imgSrc={ i18 }
                    imgAlt="Selection of pictures from article"
                    scrollSpeed = '1.1'
                    cssTextId='i18-text'
                    textHoverContent='Elle Decoration'
                    startingPosition={ pageWidth < 1100 ? 845 : 1170 }
                    leftPosition={ 5 }
                    articlePages={[ i18pg1, i18pg2, i18pg3, i18pg4 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints19"
                    cssContainerClass="mobile"
                    cssImgContainerId="i19-container"
                    imgSrc={ i19 }
                    imgAlt="Home festively decorated for Christmas"
                    scrollSpeed = '1.7'
                    cssTextId='i19-text'
                    textHoverContent='House Beautiful'
                    startingPosition={ pageWidth < 1100 ? 1160 : 1650 }
                    rightPosition={ 5 }
                    articlePages={[ i19pg1, i19pg2, i19pg3, i19pg4 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="ints20"
                    cssContainerClass="mobile"
                    cssImgContainerId="i20-container"
                    imgSrc={ i20 }
                    imgAlt="Bedroom dressed in blue tones"
                    scrollSpeed = '1.7'
                    cssTextId='i20-text'
                    textHoverContent='Emirates Home'
                    startingPosition={ pageWidth < 1100 ? 1190 : 1650 }
                    leftPosition={ 20 }
                    articlePages={[ i20pg1 ]}
                />
                <InteractiveImage
                    startingPosition={ 
                        pageWidth < 1400 ? 
                        pageWidth < 1100 ? 
                        324 : 
                        430: 
                        433 }
                        blank
                />
            </div>
        </>
    )
}

export default Interiors

