import React, { useState, useEffect } from 'react'
import LandingPage from './components/landingPage/LandingPage'
import  './App.css'
import Interiors from './components/interiors/interiors'
import BurgerMenu from './components/menu/burgerMenu'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ScrollToTop from './components/Helpers/scrollToTop'
import Travel from './components/travel/travel'
import Presenter from './components/presenter/presenter'
import FoodDrink from './components/food&drink/fooddrink'
import About from './components/about/about'
import ContactPage from './components/contact/contact'
import Features from './components/features/features'
import NewsFeed from './components/feed/feed'


const MainPage = () => {

    const [ pagePositionY, setPagePositionY ] = useState( 0 )
    const [ pageWidth, setPageWidth ] = useState( window.innerWidth )
    const [ menuClosed, setMenuClosed ] = useState( true )
 

    useEffect(() => {

        const handleWindowResize = () => {
            setPageWidth( window.innerWidth )
        }
        window.addEventListener( "resize", handleWindowResize )
        return () => {
            window.removeEventListener( "resize", handleWindowResize )
        }
    }, [ pageWidth ])

    useEffect(() => {

        const handleScroll = () => setPagePositionY( ( window.pageYOffset ) * 2 )
        window.addEventListener( "scroll", handleScroll )
        return () => {
            window.removeEventListener( "scroll", handleScroll )
        }
    }, [ pagePositionY ])

    return(
        <Router>
            <>
                <BurgerMenu 
                    menuClosed={ menuClosed }
                    setMenuClosed={ setMenuClosed }
                />
                <div>
                    <ScrollToTop />
                    <Switch>
                        <Route exact path="/" render={() => <LandingPage pagePositionY={ pagePositionY } pageWidth={ pageWidth } />}/>
                        <Route path="/about" render={() => <About />}/>
                        {/* <Route path="/latest" render={() => <NewsFeed />} /> */}
                        <Route path="/features"
                            render={() => <Features pagePositionY={ pagePositionY } pageWidth={ pageWidth } />}/>
                        <Route path="/interiors"
                            render={() => <Interiors pagePositionY={ pagePositionY } pageWidth={ pageWidth } />}/>
                        <Route path="/travel"
                        render={() => <Travel pagePositionY={ pagePositionY } pageWidth={ pageWidth } />}/>
                        <Route path="/presenter"
                            render={() => <Presenter pagePositionY={ pagePositionY } pageWidth={ pageWidth } />}/>
                        <Route path="/food&amp;drink"
                            render={() => <FoodDrink pagePositionY={ pagePositionY } pageWidth={ pageWidth } />}/>
                        <Route path="/contact"
                            render={() => <ContactPage pagePositionY={ pagePositionY } pageWidth={ pageWidth } />}/>
                        <Route pagePositionY={ pagePositionY } render={() => <LandingPage pagePositionY={ pagePositionY } />} />
                    </Switch>
                </div>
            </>
        </Router>
    )
}

export default MainPage