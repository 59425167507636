import React from 'react'

import './about.css'
import headShot from '../../assets/head shot 2.jpg'

const About = () => {

    return(
        <>
            <div id='about-wrapper'> 
                <div id="head-shot">
                    <img src={ headShot } alt='Rosalind in front of a window'/>
                </div>
                <div id='about-section-wrapper'>

                    <div id="about-section">
                        <h1> About </h1>
                        <p>
                        <span>Rosalind</span> is a Glasgow-based magazine editor turned digital editor, content creator and broadcaster who has plied her trade in some of the biggest cities in the world. After three years in London working in magazine publishing with big brands such as Hearst, Time Inc and Conde Nast, she spent three and a half years as editor of Emirates Home in 
                            Dubai where she led the complete re-design. Currently Rosalind writes about all things Scottish food and drink for the Scotsman. As well as this she co-created, co-produces and hosts the newspaper’s successful and award-nominated podcast Scran. Guests on this food and drink focused podcast include Outlander star Sam Heughan, Bake Off winner Peter Sawkins, celebrity chef Michel Roux Junior and master blender Dr Rachel Barrie.
                        <br></br>
                        <br></br>
                            Rosalind also hosts gin and whisky tastings and was the drinks expert on series one of BBC Scotland's The Great Food Guys with Nick Nairn and Dougie Vipond. 
                        <br></br>
                        <br></br>
                            Rosalind has a wealth of writing and editing experience - from long form features and breaking news stories to one-off supplements and magazines, first person pieces and reviews - she also is a talented host, featuring on TV, live events online and brand videos. Rosalind is an accomplished editor in both print and online with extensive experience in SEO, content planning and platform innovation.
                        <br></br>
                        <br></br>
                            Her passions, both in life and writing, are food, drink, interiors, design, and travel so she can often be found exploring the best bars and restaurants in Scotland and beyond. When she’s not off gallivanting, she’s kept busy by her four-year old cock-a-poo Archie, who can often be found on her Instagram account – when he sits still for long enough to take a photo!

                    
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About