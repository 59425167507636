import React from 'react'
import InteractiveImage from '../image-components/interactiveImage'
import LandingImage from '../image-components/landingImage'

import food from '../../assets/barrels.jpeg'
import fd1 from '../../assets/FoodDrink/fd1.png'
import fd1a from '../../assets/FoodDrink/fd1a.jpg'
import fd2 from '../../assets/FoodDrink/fd2.png'
import fd2a from '../../assets/FoodDrink/fd2a.jpg'
import fd3 from '../../assets/FoodDrink/fd3.png'
import fd4 from '../../assets/FoodDrink/fd4.png'
import fd5 from '../../assets/FoodDrink/fd5.jpg'
import fd5a from '../../assets/FoodDrink/fd5a.jpg'
import fd5b from '../../assets/FoodDrink/fd5b.jpg'
import fd6 from '../../assets/FoodDrink/fd6.png'
import fd7 from '../../assets/FoodDrink/fd7.png'
import fd8 from '../../assets/FoodDrink/fd8.png'
import fd9 from '../../assets/FoodDrink/fd9.png'
import fd10 from '../../assets/FoodDrink/fd10/fd10.png'
import fd10a from '../../assets/FoodDrink/fd10/fd10a.jpg'
import fd11 from '../../assets/FoodDrink/fd11/fd11.png'
import fd11a from '../../assets/FoodDrink/fd11/fd11a.jpg'
import fd12 from '../../assets/FoodDrink/fd12/fd12.png'
import fd12a from '../../assets/FoodDrink/fd12/fd12a.jpg'
import fd13 from '../../assets/FoodDrink/fd13/fd13.png'
import fd13a from '../../assets/FoodDrink/fd13/fd13a.jpg'
import fd14 from '../../assets/FoodDrink/fd14/fd14.png'
import fd14a from '../../assets/FoodDrink/fd14/fd14a.jpg'
import fd15 from '../../assets/FoodDrink/fd15/fd15.png'
import fd15a from '../../assets/FoodDrink/fd15/fd15a.jpg'
import fd16 from '../../assets/FoodDrink/fd16/fd16.png'
import fd16a from '../../assets/FoodDrink/fd16/fd16a.jpg'
import fd17 from '../../assets/FoodDrink/fd17/fd17.png'
import fd17a from '../../assets/FoodDrink/fd17/fd17a.jpg'
import fd18 from '../../assets/FoodDrink/fd18/fd18.png'
import fd18a from '../../assets/FoodDrink/fd18/fd18a.jpg'
import fd19 from '../../assets/FoodDrink/fd19/fd19.png'
import fd19a from '../../assets/FoodDrink/fd19/fd19a.jpg'
import fd20 from '../../assets/FoodDrink/fd20/fd20.png'
import fd20a from '../../assets/FoodDrink/fd20/fd20a.jpg'
import fd21 from '../../assets/FoodDrink/fd21/fd21.png'
import fd21a from '../../assets/FoodDrink/fd21/fd21a.jpg'
import fd22 from '../../assets/FoodDrink/fd22/fd22.png'
import fd22a from '../../assets/FoodDrink/fd22/fd22a.jpg'
import fd23 from '../../assets/FoodDrink/fd23/fd23.png'
import fd23a from '../../assets/FoodDrink/fd23/fd23a.jpg'
import fd24 from '../../assets/FoodDrink/fd24/fd24.png'
import fd24a from '../../assets/FoodDrink/fd24/fd24a.jpg'
import fd25 from '../../assets/FoodDrink/fd25/fd25.jpg'
import fd26 from '../../assets/FoodDrink/fd26/fd26.png'
import fd26a from '../../assets/FoodDrink/fd26/fd26a.jpg'
import fd27 from '../../assets/FoodDrink/fd27/fd27.png'
import fd27a from '../../assets/FoodDrink/fd27/fd27a.jpg'
import fd28 from '../../assets/FoodDrink/fd28/fd28.png'
import fd28a from '../../assets/FoodDrink/fd28/fd28a.jpg'
import fd29 from '../../assets/FoodDrink/fd29/fd29.png'
import fd29a from '../../assets/FoodDrink/fd29/fd29a.jpg'
import fd30 from '../../assets/FoodDrink/fd30/fd30.png'
import fd30a from '../../assets/FoodDrink/fd30/fd30a.jpg'
import fd31 from '../../assets/FoodDrink/fd31/fd31.png'
import fd31a from '../../assets/FoodDrink/fd31/fd31a.jpg'
import fd32 from '../../assets/FoodDrink/fd32/fd32.png'
import fd32a from '../../assets/FoodDrink/fd32/fd32a.jpg'
import fd33 from '../../assets/FoodDrink/fd33/fd33.png'
import fd33a from '../../assets/FoodDrink/fd33/fd33a.jpg'
import fd34 from '../../assets/FoodDrink/fd34/fdthumb34.png'
import fd34a from '../../assets/FoodDrink/fd34/fd34.jpg'
import fd35 from '../../assets/FoodDrink/fd35/fd35thumb.png'
import fd35a from '../../assets/FoodDrink/fd35/fd35.jpg'
import fd36 from '../../assets/FoodDrink/fd36/fd36thumb.png'
import fd36a from '../../assets/FoodDrink/fd36/fd36.jpg'



import './fooddrink.css'
import ScrollToTopArrow from '../Helpers/scrollToTopArrow'

const FoodDrink = ({ pagePositionY, pageWidth, pageHeight }) => {

    return(
        <>
        <ScrollToTopArrow />
            <LandingImage
                imageSource={ food }
                backgroundPosition='55% 20%'
                pagePositionY={ pagePositionY }
                pageWidth={ pageWidth }
                h1Id='landing-text-food-drink'
                h1Text='Food &amp; Drink'
            />
            <div className='images'>
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food1"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f1-container"
                    imgSrc={ fd1 }
                    imgAlt="Tweet about sexism in whisky industry"
                    scrollSpeed = '2'
                    cssTextId='f1-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 105 : 100 }
                    leftPosition={ 5 }
                    articlePages={[ fd1a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food2"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f2-container"
                    imgSrc={ fd2 }
                    imgAlt="Northwest Passage Expedition gin"
                    scrollSpeed = '1.6'
                    cssTextId='f2-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 100 : 100 }
                    rightPosition={ 5 }
                    articlePages={[ fd2a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food3"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f3-container"
                    imgSrc={ fd3 }
                    imgAlt="Old image of Glenwyvis Distillery"
                    scrollSpeed = '1.3'
                    cssTextId='f3-text'
                    textHoverContent='The Scotsman'
                    startingPosition={ pageWidth  < 1100 ? 138 : 145 }
                    leftPosition={ 5 }
                    linkOut
                    articleLink={ 'https://www.scotsman.com/interactive/glenwyvis-distillery'}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food4"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f4-container"
                    imgSrc={ fd4 }
                    imgAlt='Rare collection of whiskies for auction'
                    scrollSpeed = '1'
                    cssTextId='f4-text'
                    textHoverContent='The Scotsman'
                    startingPosition={ pageWidth  < 1100 ? 190 : 230 }
                    leftPosition={ 5 }
                    linkOut
                    articleLink={ 'https://www.scotsman.com/interactive/whisky-perfect-collection-auction'}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food5"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f5-container"
                    imgSrc={ fd5 }
                    imgAlt='Produce from Romaine Calm'
                    scrollSpeed = '1.5'
                    cssTextId='f5-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 315 : 400 }
                    rightPosition={ 10 }
                    articlePages={[ fd5a, fd5b ]}
                    pageGap

                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food6"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f6-container"
                    imgSrc={ fd6 }
                    imgAlt='Chef, Derek Johnstone'
                    scrollSpeed = '1.2'
                    cssTextId='f6-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 300 : 390 }
                    leftPosition={ 5 }
                    linkOut
                    articleLink={ 'https://foodanddrink.scotsman.com/food/historic-scottish-castle-hires-award-winning-chef/'}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food7"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f7-container"
                    imgSrc={ fd7 }
                    imgAlt='Magnum of wine being poured'
                    scrollSpeed = '0.8'
                    cssTextId='f7-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 280 : 360 }
                    rightPosition={ 10 }
                    linkOut
                    articleLink={ 'https://foodanddrink.scotsman.com/drink/winning-formula-expect-ivy-comes-town/'}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food8"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f8-container"
                    imgSrc={ fd8 }
                    imgAlt=''
                    scrollSpeed = '1.2'
                    cssTextId='f8-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 380 : 520 }
                    leftPosition={ 5 }
                    linkOut
                    articleLink={ 'https://foodanddrink.scotsman.com/drink/behind-scenes-worlds-travelled-pop-restaurant-comes-glasgow/'}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food9"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f9-container"
                    imgSrc={ fd9 }
                    imgAlt=''
                    scrollSpeed = '0.9'
                    cssTextId='f9-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 360 : 500 }
                    rightPosition={ 15 }
                    linkOut
                    articleLink={ 'https://foodanddrink.scotsman.com/food/the-story-of-83-hanover-street-edinburgh/'}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food10"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f10-container"
                    imgSrc={ fd10 }
                    imgAlt='cocktail in short glass'
                    scrollSpeed = '0.9'
                    cssTextId='f10-text'
                    textHoverContent='Edinburgh Evening News'
                    startingPosition={ pageWidth  < 1100 ? 420 : 580 }
                    leftPosition={ 10 }
                    articlePages={[ fd10a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food11"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f11-container"
                    imgSrc={ fd11 }
                    imgAlt='Grace Noble with highland cow'
                    scrollSpeed = '1.1'
                    cssTextId='f11-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 500 : 670 }
                    rightPosition={ 5 }
                    articlePages={[ fd11a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food12"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f12-container"
                    imgSrc={ fd12 }
                    imgAlt='Gin miniatures'
                    scrollSpeed = '0.6'
                    cssTextId='f12-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 390 : 530 }
                    leftPosition={ 5 }
                    articlePages={[ fd12a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food13"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f13-container"
                    imgSrc={ fd13 }
                    imgAlt='Paper headline'
                    scrollSpeed = '1.8'
                    cssTextId='f13-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 800 : 1150 }
                    rightPosition={ 10 }
                    articlePages={[ fd13a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food14"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f14-container"
                    imgSrc={ fd14 }
                    imgAlt='Nadar vodka bottle'
                    scrollSpeed = '0.9'
                    cssTextId='f14-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 550 : 750 }
                    leftPosition={ 10 }
                    articlePages={[ fd14a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food15"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f15-container"
                    imgSrc={ fd15 }
                    imgAlt='Nick Nairn and Dougie Vipond'
                    scrollSpeed = '1.4'
                    cssTextId='f15-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 750 : 1050 }
                    rightPosition={ 10 }
                    articlePages={[ fd15a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food16"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f16-container"
                    imgSrc={ fd16 }
                    imgAlt='Video drinks event'
                    scrollSpeed = '0.9'
                    cssTextId='f16-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 600 : 850 }
                    rightPosition={ 25 }
                    articlePages={[ fd16a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food17"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f17-container"
                    imgSrc={ fd17 }
                    imgAlt='Ready, Steady, Cook presenter and chefs'
                    scrollSpeed = '1.4'
                    cssTextId='f17-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 900 : 1300 }
                    leftPosition={ 5 }
                    articlePages={[ fd17a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food18"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f18-container"
                    imgSrc={ fd18 }
                    imgAlt='Pier Brasserie'
                    scrollSpeed = '1.7'
                    cssTextId='f18-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 1050 : 1495 }
                    rightPosition={ 5 }
                    articlePages={[ fd18a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food19"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f19-container"
                    imgSrc={ fd19 }
                    imgAlt='Bootlegger&#39;s Bothy'
                    scrollSpeed = '0.7'
                    cssTextId='f19-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 650 : 900 }
                    leftPosition={ 15 }
                    articlePages={[ fd19a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food20"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f20-container"
                    imgSrc={ fd20 }
                    imgAlt='Cragellachie Hotel'
                    scrollSpeed = '1'
                    cssTextId='f20-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 890 : 1240 }
                    rightPosition={ 15 }
                    articlePages={[ fd20a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food21"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f21-container"
                    imgSrc={ fd21 }
                    imgAlt='Rose Street Edinburgh'
                    scrollSpeed = '0.9'
                    cssTextId='f21-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 820 : 1170 }
                    leftPosition={ 15 }
                    articlePages={[ fd21a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food22"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f22-container"
                    imgSrc={ fd22 }
                    imgAlt='A scottish strawberry being picked'
                    scrollSpeed = '1.2'
                    cssTextId='f22-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 1035 : 1505 }
                    leftPosition={ 10 }
                    articlePages={[ fd22a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food23"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f23-container"
                    imgSrc={ fd23 }
                    imgAlt='Stevie McLaughlin'
                    scrollSpeed = '1.5'
                    cssTextId='f23-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 1230 : 1750 }
                    rightPosition={ 25 }
                    articlePages={[ fd23a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food24"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f24-container"
                    imgSrc={ fd24 }
                    imgAlt='News headline'
                    scrollSpeed = '1.8'
                    cssTextId='f24-text'
                    textHoverContent='The i'
                    startingPosition={ pageWidth  < 1100 ? 1450 : 2090 }
                    rightPosition={ 15 }
                    articlePages={[ fd24a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food25"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f25-container"
                    imgSrc={ fd25 }
                    imgAlt='News clipping'
                    scrollSpeed = '0.9'
                    cssTextId='f25-text'
                    textHoverContent='The Scotsman'
                    startingPosition={ pageWidth  < 1100 ? 900 : 1320 }
                    leftPosition={ 10 }
                    articlePages={[ fd25 ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food26"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f26-container"
                    imgSrc={ fd26 }
                    imgAlt='Cranside artwork'
                    scrollSpeed = '0.6'
                    cssTextId='f26-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 760 : 1100 }
                    rightPosition={ 10 }
                    articlePages={[ fd26a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food27"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f27-container"
                    imgSrc={ fd27 }
                    imgAlt='Edinburgh Castle viewed from Cold Town House roof'
                    scrollSpeed = '0.9'
                    cssTextId='f27-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 970 : 1450 }
                    leftPosition={ 15 }
                    articlePages={[ fd27a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food28"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f28-container"
                    imgSrc={ fd28 }
                    imgAlt='Johhnie Walker black label'
                    scrollSpeed = '0.9'
                    cssTextId='f28-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 1050 : 1500 }
                    leftPosition={ 7 }
                    articlePages={[ fd28a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food29"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f29-container"
                    imgSrc={ fd29 }
                    imgAlt='Lady Janet Anstruther&apos;s Tower'
                    scrollSpeed = '1.3'
                    cssTextId='f29-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 1330 : 1950 }
                    rightPosition={ 2 }
                    articlePages={[ fd29a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food30"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f30-container"
                    imgSrc={ fd30 }
                    imgAlt='News Headline'
                    scrollSpeed = '0.9'
                    cssTextId='f30-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 1100 : 1600 }
                    leftPosition={ 42 }
                    articlePages={[ fd30a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food31"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f31-container"
                    imgSrc={ fd31 }
                    imgAlt='Barbeque'
                    scrollSpeed = '0.5'
                    cssTextId='f31-text'
                    textHoverContent='Edinburgh Evening News'
                    startingPosition={ pageWidth  < 1100 ? 820 : 1170 }
                    leftPosition={ 8 }
                    articlePages={[ fd31a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food32"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f32-container"
                    imgSrc={ fd32 }
                    imgAlt='Gin and tonic'
                    scrollSpeed = '0.9'
                    cssTextId='f32-text'
                    textHoverContent='Edinburgh Evening News'
                    startingPosition={ pageWidth  < 1100 ? 1170 : 1700 }
                    rightPosition={ 5 }
                    articlePages={[ fd32a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food33"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f33-container"
                    imgSrc={ fd33 }
                    imgAlt='Pizza sign'
                    scrollSpeed = '0.5'
                    cssTextId='f33-text'
                    textHoverContent='Edinburgh Evening News'
                    startingPosition={ pageWidth  < 1100 ? 870 : 1250 }
                    leftPosition={ 15 }
                    articlePages={[ fd33a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food34"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f34-container"
                    imgSrc={ fd34 }
                    imgAlt='Alchemilla interior'
                    scrollSpeed = '0.2'
                    cssTextId='f34-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 640 : 910 }
                    rightPosition={ 10 }
                    articlePages={[ fd34a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food35"
                    cssContainerClass="mobile smaller-font"
                    cssImgContainerId="f35-container"
                    imgSrc={ fd35 }
                    imgAlt='Julies Kopitiam'
                    scrollSpeed = '0.5'
                    cssTextId='f35-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 910 : 1300 }
                    leftPosition={ 10 }
                    articlePages={[ fd35a ]}
                />
                <InteractiveImage 
                    pagePositionY={ pagePositionY } 
                    pageWidth={ pageWidth } 
                    cssContainerId="food36"
                    cssContainerClass="mobile large-font"
                    cssImgContainerId="f36-container"
                    imgSrc={ fd36 }
                    imgAlt='Ziques exterior signage'
                    scrollSpeed = '0.2'
                    cssTextId='f36-text'
                    textHoverContent='The Scotsman Food &amp; Drink'
                    startingPosition={ pageWidth  < 1100 ? 670 : 960 }
                    rightPosition={ 22.5 }
                    articlePages={[ fd36a ]}
                />
                <InteractiveImage
                    startingPosition={ 
                        pageWidth < 1400 ? 
                        pageWidth < 1100 ? 
                        514 : 
                        728 : 
                        735 }
                        blank
                />
            </div>
        </>
            
    )
}

export default FoodDrink