import React from 'react'
import './scrollToTopArrow.css'


const ScrollToTopArrow = () => {

    const handleClick = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }
    
    return(
        <span id='scroll-top-arrow' onClick={ handleClick }><span className='up-arrow'></span></span>
    )
}

export default ScrollToTopArrow